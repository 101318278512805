<template>
  <v-row>
    <v-col cols="12" md="5">
      <h3>Olá {{ user.name.split(" ")[0] }}, tudo bem?</h3>
      <p>
        Seja bem-vindo! Aqui você irá visualizar suas locações, detalhes dos imóveis, pagamentos, contratos e muito
        mais...
      </p>
      <p v-if="rentals.length">
        Para iniciar, em <b>"Minhas Locações"</b> que se encontra no menu principal, clique na{{
          rentals.length > 1 ? "s" : ""
        }}
        locaç{{ rentals.length > 1 ? "ões" : "ão" }} disponível{{ rentals.length > 1 ? "is" : "" }}.
      </p>
    </v-col>
    <v-col cols="12" md="7">
      <v-img max-width="500" class="mt-2" src="@/assets/images/common/dashboard-client.svg"></v-img>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      rentals: "rental/rentals"
    })
  }
};
</script>
